import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import DatoPage from "../components/DatoPage"

export default function Blog(props) {
  const { data } = props

  // console.log("Template - datoPage: ", data)

  return (
    <Layout pageTitle={data.datoCmsPage.title}>
      <DatoPage data={data} />
    </Layout>
  )
}

export const query = graphql`
  query($originalId: String!) {
    datoCmsPage(originalId: { eq: $originalId }) {
      title
      url
      locale
      id
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

// export const query = graphql`
//   query($id: String!) {
//     datoCmsPage(id: { eq: $id }) {
//       title
//       url
//       locale
//       id
//       bodyNode {
//         childMarkdownRemark {
//           html
//         }
//       }
//     }
//   }
// `
