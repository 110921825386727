import React from "react"
import classes from "./index.module.scss"

const index = ({
  data: {
    datoCmsPage: { title, bodyNode }
  }
}) => {
  return (
    <section className={classes.root}>
      <div className={classes.titleContainer}>
        <h1 className={classes.title}>{title}</h1>
      </div>
      <div
        className={classes.markdown}
        dangerouslySetInnerHTML={{ __html: bodyNode.childMarkdownRemark.html }}
      />
    </section>
  )
}

export default index
